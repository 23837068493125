@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --accent-color: #007cc2;
    --mainFont: 'Alexandria', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
    height: 12px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: var(--accent-color);
    border: 0px none #ffffff;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--accent-color);
}

::-webkit-scrollbar-thumb:active {
    background: var(--accent-color);
}

::-webkit-scrollbar-track {
    background: #fff;
    border: 0px none #ffffff;
    border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
    background: #fff;
}

::-webkit-scrollbar-track:active {
    background: #fff;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

a {
    color: var(--accent-color);
    text-decoration: none !important;
}
a:hover{
    color: var(--accent-color);
    opacity: .8;
}

body {
    font-family: var(--mainFont);
}

.stores-area {
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
}

.info-area {
    box-shadow: 3px 0 20px 0px rgb(0 0 0 / 18%);
    border-right: 1px solid rgb(0 0 0 / 5%);
}

.store-item {
    position: relative;
    overflow: hidden;
}

.store-item .title {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    text-transform: uppercase;
    font-size: 25px;
    text-shadow: 0 0 10px rgb(0 0 0 / 38%);
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    width: 80%;
}

.store-item a:after {
    content: "";
    background-color: var(--accent-color);
    opacity: .5;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    position: absolute;
    transition: .2s;
}

.store-img-bg {
    height: 50vh;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 1.5s;
}

.store-item a:hover .store-img-bg {
    transform: scale(1.1) !important;
}

.uc-section {
    height: 100vh;
}

.animate__delay-0-3s {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}